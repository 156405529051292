import { Dispatch, FC, SetStateAction } from 'react';
import useVeevaInsciteSyncTypeModal from './VeevaInsciteSyncTypeModal.hook';
import ActionButton from 'components/ActionButton';
import DefaultDialogTitle from 'components/Dialogs/DefaultDialogTitle';
import { useGetVeevaInsciteDocuments } from 'modules/events/graphql/queries/getVeevaInsciteData';
import Box from 'yoda-ui/components/Box';
import Dialog from 'yoda-ui/components/Dialogs/Dialog';
import SelectAsync from 'yoda-ui/components/Form/SelectAsync';
import { YodaFormProvider } from 'yoda-ui/yodaForm';
import { YodaBorderRadius, YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

type VeevaInsciteSyncTypeModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setFormOpen: Dispatch<SetStateAction<boolean>>;
  onCancel?: () => void;
  pdfUrl: string;
};

const VeevaInsciteSyncTypeModal: FC<VeevaInsciteSyncTypeModalProps> = ({ open, setOpen, setFormOpen, onCancel }) => {
  const {
    modalConfig,
    providerFields,
    syncExisting,
    setSyncExisting,
    handleDocumentChange,
    t,
  } = useVeevaInsciteSyncTypeModal(setOpen, setFormOpen);

  const cancelDialogForm = () => {
    if (onCancel) {
      onCancel();
    }
    setSyncExisting(false);
    setOpen(false);
  };
  return (

    <YodaFormProvider { ...providerFields }>
      <Dialog
        open={ open }
        maxWidth="md"
        borderRadius={ YodaBorderRadius.medium }
        fullWidth={ true }
        minHeight='50%'
      >
        <Box padding={ YodaSpacing.xLarge } justifyContent={ YodaJustifyContent.center }>
          <DefaultDialogTitle cancelDialog={ cancelDialogForm }>{ t('events_veeva_inscite_sync_modal_title') }</DefaultDialogTitle>
          <Box marginTop={ YodaSpacing.medium }>
            <ActionButton { ...modalConfig.syncNewButton }/>
          </Box>
          <Box marginTop={ YodaSpacing.medium }>
            <ActionButton { ...modalConfig.syncExistingButton } />
          </Box>
          {
            syncExisting
      && <>
        <Box margin={ YodaSpacing.small }>
          <SelectAsync
            label={ t('events_veeva_inscite_sync_modal_select_title') }
            lazyQueryHook={ useGetVeevaInsciteDocuments }
            onChange={ handleDocumentChange }
            placeholder={ t('events_veeva_inscite_sync_modal_select_placeholder') }
            minQueryLength={ 3 }
          />
        </Box>
        <ActionButton { ...modalConfig.submitExistingIdButton }/>
      </>
          }
        </Box>
      </Dialog>
    </YodaFormProvider>
  );
};

export default VeevaInsciteSyncTypeModal;
