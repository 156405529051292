import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VeevaInsciteDocumentItem } from '../VeevaInsciteSyncModal/VeevaInsciteSyncModal.types';
import { SelectOption } from 'basics/options/options.types';
import { VeevaInsciteSyncContext } from 'contexts/veevaInsciteSync';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const useVeevaInsciteUploadModal = (setOpen: Dispatch<SetStateAction<boolean>>,
  setFormOpen: Dispatch<SetStateAction<boolean>>) => {
  const { t } = useTranslation();
  const { providerFields } = useYodaCreateForm();
  const [syncExisting, setSyncExisting] = useState(false);
  const veevaInsciteSyncContext = useContext(VeevaInsciteSyncContext);

  const handleSyncNewClick = async () => {
    setOpen(false);
    veevaInsciteSyncContext.setDocument(null);
    setFormOpen(true);
  };

  const handleSyncExistingClick = async () => {
    setSyncExisting(true);
  };

  const handleSubmitIdClick = async () => {
    setOpen(false);
    setSyncExisting(false);
    setFormOpen(true);
  };

  const handleDocumentChange = async <T extends SelectOption>(value: T | null) => {
    if (value) {
      // Assign retrieved documents as ID of select field to put it in context without additional get request
      veevaInsciteSyncContext.setDocument(value?.id as unknown as VeevaInsciteDocumentItem);
    }
  };

  const modalConfig = {
    syncNewButton: {
      buttonProps: {
        onClick: handleSyncNewClick,
        buttonType: ButtonType.primary,
      },
      label: t('events_veeva_inscite_sync_new_button_label'),
    },
    syncExistingButton: {
      buttonProps: {
        onClick: handleSyncExistingClick,
        buttonType: ButtonType.secondary,
      },
      label: t('events_veeva_inscite_sync_exitsing_button_label'),
    },
    submitExistingIdButton: {
      buttonProps: {
        onClick: handleSubmitIdClick,
        buttonType: ButtonType.primary,
      },
      label: t('events_veeva_inscite_submit_document_id_label'),
    },
  };

  return {
    modalConfig,
    providerFields,
    syncExisting,
    setSyncExisting,
    handleDocumentChange,
    t,
  };
};

export default useVeevaInsciteUploadModal;
