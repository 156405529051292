import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PicklistOptions, VeevaInsciteDocumentTypeItem, VeevaInscitePicklistItem } from './VeevaInsciteSyncModal.types';
import { VeevaInsciteSyncContext } from 'contexts/veevaInsciteSync';
import { DocumentPropertyResponse } from 'generated/event.types';
import { veevaInsciteSyncModalFields } from 'modules/events/basics/constants/commonFormFields.constants';

import { useGetVeevaInsciteDocumentType, useGetVeevaInsciteDocumentTypes } from 'modules/events/graphql/queries/getVeevaInsciteData';
import useGetVeevaInscitePicklistValues from 'modules/events/graphql/queries/getVeevaInscitePicklistValues';

const useVeevaInsciteSyncModalConfig = () => {
  const { t } = useTranslation();
  const { document } = useContext(VeevaInsciteSyncContext);
  const [documentInputFields, setDocumentInputFields] = useState<DocumentPropertyResponse[]>([]);
  const [picklistFieldsOptions, setPicklistFieldsOptions] = useState<PicklistOptions>({});
  const [parentType, setParentType] = useState('');
  const { data: typeData } = useGetVeevaInsciteDocumentTypes();
  const { queryFetchDocumentType } = useGetVeevaInsciteDocumentType();
  const { queryFetchPicklistValues } = useGetVeevaInscitePicklistValues();
  const { data: subtypeData, loading } = useGetVeevaInsciteDocumentTypes(parentType);

  const transformDataToSelectOptions = (arrayData: VeevaInsciteDocumentTypeItem[] | VeevaInscitePicklistItem[]) => {
    return arrayData.map((item) => ({
      id: item.label || '',
      label: item.label || '',
      key: (item as VeevaInscitePicklistItem).name || (item as VeevaInsciteDocumentTypeItem).value,
    }));
  };

  const getPicklistOptions = useCallback(async (fieldName: string) => {
    const { data } = await queryFetchPicklistValues(
      { variables: { objectType: fieldName === 'copy_reason__v' ? 'copy_reason_picklist__v' : fieldName } },
    );
    return data?.getVeevaInscitePicklistValues
      ? transformDataToSelectOptions(data?.getVeevaInscitePicklistValues.picklistValues)
      : [];
  }, [queryFetchPicklistValues]);

  const fetchFields = useCallback(async (documentType: string) => {
    const { data } = await queryFetchDocumentType({ variables: { documentType } });
    setDocumentInputFields(data?.getVeevaInsciteDocumentType?.fields || []);
  }, [queryFetchDocumentType]);

  const documentTypeOptions = useMemo(() => transformDataToSelectOptions(typeData), [typeData]);
  const documentSubTypeOptions = useMemo(() => transformDataToSelectOptions(subtypeData), [subtypeData]);

  useEffect(() => {
    const currentDocumentType = documentTypeOptions?.find((documentType) => documentType.label === document?.type)?.key;
    if (currentDocumentType) {
      setParentType(currentDocumentType);
      fetchFields(currentDocumentType);
    }
  }, [document, documentTypeOptions, fetchFields]);

  useEffect(() => {
    const fetchOptions = async () => {
      const fetchPromises = documentInputFields.filter((field) => { return field.type === 'Picklist'; }).map(async (testField) => {
        const picklistOptions = await getPicklistOptions(testField.name);
        return { name: testField?.name, options: picklistOptions || [] };
      });

      const results = await Promise.all(fetchPromises);
      const newOptionsMap: PicklistOptions = results.reduce((acc, { name, options }) => {
        acc[name] = options;
        return acc;
      }, {} as PicklistOptions);
      setPicklistFieldsOptions(newOptionsMap); // Update state
    };

    if (documentInputFields.length > 0) {
      fetchOptions();
    }
  }, [documentInputFields, getPicklistOptions]);

  const onDocumentTypeSelect = (event: ChangeEvent<HTMLInputElement>, value: { id: string; label: string; key: string }) => {
    setParentType(value?.key || '');
    fetchFields(value?.key);
  };

  const veevaInsciteSyncModalConfig = {
    documentType: {
      label: t('events_veeva_inscite_sync_type_label'),
      placeholder: t('events_veeva_inscite_sync_type_placeholder'),
      onChange: onDocumentTypeSelect,
      name: veevaInsciteSyncModalFields.type,
      options: documentTypeOptions || [],
      defaultValue: document?.type || null,
      disabled: loading || !!document?.id,
      required: true,
    },
    documentSubType: {
      label: t('events_veeva_inscite_sync_subtype_label'),
      placeholder: t('events_veeva_inscite_sync_type_placeholder'),
      name: veevaInsciteSyncModalFields.subtype,
      options: parentType ? documentSubTypeOptions : [],
      defaultValue: document?.subtype || null,
      disabled: loading || (!!document?.id && !!document?.subtype),
      required: true,
    },
  };

  return {
    documentInputFields,
    veevaInsciteSyncModalConfig,
    picklistFieldsOptions,
  };
};

export default useVeevaInsciteSyncModalConfig;
