import { useTranslation } from 'react-i18next';
import { boolean, date, mixed, string } from 'yup';
import { TimeZone } from 'basics/enums/timeZoneList.enums';
import { TestContextType } from 'basics/types/common.types';
import { testEndDayNotBeforeStartDay, testStartDateIsSet, testStartDateNotInPast } from 'basics/utils/dates/dates.utils';
import { Format, HostProvider } from 'generated/event.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { TimeSlotBookingField } from 'modules/events/components/TimeSlotBooking/TimeSlotBooking.types';
import { YodaFieldsState } from 'yoda-ui/yodaForm/yodaForm.types';

const useTimeSlotBookingSchema = () => {
  const { t } = useTranslation();
  const requiredLabel = t('form_errors_required');

  return {
    [TimeSlotBookingField.dateRange]: mixed()
      .test({
        name: 'startDate is set',
        message: requiredLabel,
        test: testStartDateIsSet,
      })
      .test({
        name: 'startDate is in future',
        message: t('timeSlotBooking_startDate_errorMessage_future'),
        test: testStartDateNotInPast,
      })
      .test({
        name: 'start date before end date',
        message: t('timeSlotBooking_endDate_errorMessage_earlierThanStart'),
        test: testEndDayNotBeforeStartDay,
      }),
    [TimeSlotBookingField.timeZone]: mixed<TimeZone>()
      .oneOf(Object.values(TimeZone), requiredLabel)
      .required(requiredLabel),
    [TimeSlotBookingField.startTime]: string()
      .required(requiredLabel),
    [TimeSlotBookingField.endTime]: string()
      .required(requiredLabel),
    [TimeSlotBookingField.hostProvider]: mixed<HostProvider>()
      .oneOf(Object.values(HostProvider), requiredLabel)
      .required(),
    [TimeSlotBookingField.locale]: string()
      .required(),
    [TimeSlotBookingField.hostProviderLink]: string().test({
      name: 'Host Provider link is required',
      message: t('timeSlotBooking_hostProviderLink_errorMessage_isRequired'),
      test: (hostProviderLink, context) => {
        const fieldsState: YodaFieldsState = (context.options as TestContextType)?.fieldsState;
        const validHostProviderLink = !!(hostProviderLink && hostProviderLink.length > 0);
        return (fieldsState[commonFields.hostProvider]?.value === HostProvider.other && validHostProviderLink)
        || fieldsState[commonFields.hostProvider]?.value === HostProvider.zoom;
      },
    }).nullable(true),
    [TimeSlotBookingField.eventFormat]: mixed<Format>()
      .oneOf(Object.values(Format), requiredLabel)
      .required(),
    [TimeSlotBookingField.enableZoomStreaming]: boolean().notRequired(),
    [TimeSlotBookingField.registrationEndDate]: date().nullable(),
  };
};

export default useTimeSlotBookingSchema;
