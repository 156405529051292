import { Dispatch, FC, ReactNode, SetStateAction, createContext, useState } from 'react';
import { VeevaInsciteDocumentItem } from 'modules/events/components/EventTesting/VeevaInsciteSyncModal/VeevaInsciteSyncModal.types';

type VeevaInsciteSyncProviderProps = {
  children: ReactNode;
};

type VeevaInsciteSyncContextType = {
  document: VeevaInsciteDocumentItem | null;
  setDocument: Dispatch<SetStateAction<VeevaInsciteDocumentItem | null>>;
};

const initialState = {
  document: null,
  setDocument: () => {},
};

export const VeevaInsciteSyncContext = createContext<VeevaInsciteSyncContextType>(initialState);

export const VeevaInsciteSyncProvider: FC<VeevaInsciteSyncProviderProps> = ({ children }) => {
  const [document, setDocument] = useState<VeevaInsciteDocumentItem | null>(null);

  const contextValue = {
    document,
    setDocument,
  };

  return (
    <VeevaInsciteSyncContext.Provider value={ contextValue }>
      { children }
    </VeevaInsciteSyncContext.Provider>
  );
};
