import { useTranslation } from 'react-i18next';
import { boolean, mixed, string } from 'yup';
import { EventPromotionalityEnum } from 'modules/events/basics/enums/events.enums';

const useBiogenlincConfigSchema = () => {
  const { t } = useTranslation();
  const maxPrcCodeLength = 255;
  const maxPrcCodeMessage = `${t('events_prc_code_input_max_length_error_message', { count: maxPrcCodeLength })}`;

  return {
    isVisibleOnBiogenLinc: boolean(),
    isPublic: mixed<EventPromotionalityEnum>()
      .oneOf(Object.values(EventPromotionalityEnum)),
    prcCodeEventDetails: string()
      .required()
      .max(maxPrcCodeLength, maxPrcCodeMessage),
  };
};

export default useBiogenlincConfigSchema;
