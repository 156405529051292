import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useVeevaInsciteSyncModalConfig from './VeevaInsciteSyncModal.config';
import { errorToast, succesToast } from 'basics/utils/toast';
import { VeevaInsciteSyncContext } from 'contexts/veevaInsciteSync';
import { useSyncInsciteDocumentMutation } from 'modules/events/graphql/mutations/syncInsciteDocument';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const useVeevaInsciteSyncModal = (setOpen: Dispatch<SetStateAction<boolean>>,
  pdfUrl: string) => {
  const { t } = useTranslation();

  const {
    getValues,
    providerFields,
    resetForm,
  } = useYodaCreateForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [syncInsciteDocument] = useSyncInsciteDocumentMutation();
  const veevaInsciteSyncContext = useContext(VeevaInsciteSyncContext);
  const { documentInputFields, veevaInsciteSyncModalConfig, picklistFieldsOptions } = useVeevaInsciteSyncModalConfig();

  const handleSubmitClick = async () => {
    setIsSubmitting(true);
    const formValues = getValues();
    formValues.id = veevaInsciteSyncContext.document?.id;
    const { veevaSyncShouldUpdateFile, ...documentFormValues } = formValues;
    const syncResponse = await syncInsciteDocument(documentFormValues, pdfUrl, veevaSyncShouldUpdateFile);
    if (syncResponse?.success) {
      succesToast('Successfully create/update inscite document');
    } else {
      errorToast('Error while create/update inscite document');
    }
    setIsSubmitting(false);
    setOpen(false);
    resetForm();
  };

  const buttonsConfig = {
    submitButton: {
      buttonProps: {
        onClick: handleSubmitClick,
        buttonType: ButtonType.primary,
      },
      label: t('events_confirm_upload'),
      loading: isSubmitting,
    },
    cancelButton: {
      buttonProps: {
        onClick: () => {
          resetForm();
          setOpen(false);
        },
        buttonType: ButtonType.secondary,
      },
      label: t('actions_cancel_label'),
    },
  };

  return {
    veevaInsciteSyncModalConfig,
    buttonsConfig,
    providerFields,
    documentInputFields,
    documentId: veevaInsciteSyncContext.document?.id,
    documentProperties: veevaInsciteSyncContext.document?.props,
    picklistFieldsOptions,
    t,
  };
};

export default useVeevaInsciteSyncModal;
