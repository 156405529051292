import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { SubmitDocumentStatus, VeevaDocumentInput } from 'generated/event.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const syncInsciteDocumentMutationCall = gql`
    mutation syncInsciteDocument ($document: VeevaDocumentInput!, $pdfUrl: String!, $shouldUpdateFile: Boolean!) {
      syncInsciteDocument(document: $document, pdfUrl: $pdfUrl, shouldUpdateFile: $shouldUpdateFile) {
        upload {
          type
          message
        }
        props {
          type
          message
        }
        success
        id
      }
    }`;

type ResultType = {
  syncInsciteDocument: SubmitDocumentStatus | null;
};

type UseSyncInsciteDocumentMutation = [
  (document: VeevaDocumentInput, pdfUrl: string, shouldUpdateFile: boolean) => Promise<SubmitDocumentStatus | null>,
  MutationResult<ResultType>,
];

export const useSyncInsciteDocumentMutation = (): UseSyncInsciteDocumentMutation => {
  const [syncInsciteDocument, state] = useMutation<ResultType>(syncInsciteDocumentMutationCall);

  const executeMutation = useCallback(async (document: VeevaDocumentInput, pdfUrl, shouldUpdateFile) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { id = null, type__v, subtype__v, ...restFields } = document;
    const dataFields = Object.entries(restFields).map(([key, value]) => ({
      label: key,
      value,
    }));
    try {
      const { data } = await syncInsciteDocument({
        variables: {
          document: { id, type__v, subtype__v, dataFields },
          pdfUrl,
          shouldUpdateFile: id ? shouldUpdateFile : true,
        },
      });

      return data?.syncInsciteDocument || null;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'syncInsciteDocument.ts - syncInsciteDocumentMutation',
        });
      }
      return null;
    }
  }, [syncInsciteDocument]);

  return [executeMutation, state];
};
