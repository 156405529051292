import { Dispatch, FC, SetStateAction } from 'react';
import useVeevaInsciteSyncModal from './VeevaInsciteSyncModal.hook';
import DialogForm from 'components/DialogForm';
import { veevaInsciteSyncModalFields } from 'modules/events/basics/constants/commonFormFields.constants';
import Box from 'yoda-ui/components/Box';
import DatePickerYoda from 'yoda-ui/components/Form/DatePickerYoda';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import SwitchYoda from 'yoda-ui/components/Form/SwitchYoda';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import { YodaFormProvider } from 'yoda-ui/yodaForm';
import { YodaFontSize, YodaFontWeight, YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

type VeevaInsciteSyncModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  pdfUrl: string;
};

const VeevaInsciteSyncModal: FC<VeevaInsciteSyncModalProps> = ({ open, setOpen, pdfUrl }) => {
  const {
    veevaInsciteSyncModalConfig,
    buttonsConfig,
    providerFields,
    documentInputFields,
    documentId,
    picklistFieldsOptions,
    documentProperties,
    t,
  } = useVeevaInsciteSyncModal(setOpen, pdfUrl);
  return (
    <YodaFormProvider { ...providerFields }>
      <DialogForm
        dialogTitle={ t('events_veeva_inscite_sync_modal_title') }
        open={ open }
        setOpen={ setOpen }
        cancelButtonConfig={ buttonsConfig.cancelButton }
        submitButtonConfig={ buttonsConfig.submitButton }
        justifyActions={ YodaJustifyContent.center }
        fullWidth={ true }
      >
        {
          documentId
        && <Box marginBottom={ YodaSpacing.medium }>
          <Box fontSize={ YodaFontSize.large }>
            { t('events_veeva_inscite_sync_modal_upload_file') }
          </Box>
          <SwitchYoda name={ veevaInsciteSyncModalFields.shouldUpdateFile }
            defaultValue={ true }
            disabled={ false } />
        </Box>
        }
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginBottom={ YodaSpacing.medium }>
          <Box minWidth={ '45%' } margin={ YodaSpacing.medium }>
            <SelectYoda
              { ...veevaInsciteSyncModalConfig.documentType } />
          </Box>
          <Box minWidth={ '45%' } margin={ YodaSpacing.medium }>
            <SelectYoda
              { ...veevaInsciteSyncModalConfig.documentSubType } />
          </Box>
        </Box>
        {
          documentInputFields.map((item) => (
            <Box
              key={ item.name }
              marginBottom={ YodaSpacing.medium }
              minWidth="100%"
            >
              {
                item.type === 'Picklist' ? (
                  <SelectYoda
                    name={ item.name }
                    label={ item.label || '' }
                    options={ picklistFieldsOptions[item.name] || [] }
                    defaultValue={ documentProperties?.find((property) => property.label === item.name)?.value || '' }
                    required={ item.required }
                    disabled={ false }
                  />
                ) : item.type === 'String' || item.type === 'Number' ? (
                  <TextYoda
                    name={ item.name }
                    label={ item.label || 'label' }
                    defaultValue={ documentProperties?.find((property) => property.label === item.name)?.value || '' }
                    placeholder=''
                    required={ item.required }
                    disabled={ false }
                  />
                ) : item.type === 'Date' || item.type === 'DateTime' ? (
                  <DatePickerYoda
                    name={ item.name }
                    label={ item.label || 'label' }
                    defaultValue={
                      documentProperties?.find((property) => property.label === item.name)?.value
                        ? new Date(documentProperties?.find((property) => property.label === item.name)?.value as string)
                        : undefined
                    }
                    required={ item.required }
                    disabled={ false }
                    minDate={ new Date() }
                  />
                ) : null
              }
              <Box fontSize={ YodaFontSize.small } fontWeight={ YodaFontWeight.light } marginBottom={ YodaSpacing.small }>
                { item.helpContent || '' }
              </Box>
            </Box>
          ))
        }
      </DialogForm>
    </YodaFormProvider>
  );
};

export default VeevaInsciteSyncModal;
