import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { DocumentPropertyResponse, DocumentTypeReference } from 'generated/event.types';
import {
  VeevaInsciteDocumentItem,
  VeevaInsciteDocumentTypeItem,
} from 'modules/events/components/EventTesting/VeevaInsciteSyncModal/VeevaInsciteSyncModal.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_DOCUMENT_TYPES = gql`
query GetVeevaInsciteDocumentTypesQuery($parentType: String!) {
  getVeevaInsciteDocumentTypes(parentType: $parentType) {
    insciteFieldData {
      label
      value
    }
  }
}
`;

const GET_DOCUMENT_TYPE = gql`
query GetVeevaInsciteDocumentTypeQuery($documentType: String!) {
  getVeevaInsciteDocumentType(documentType: $documentType) {
    value
    label
    fields {
      name
      type
      required
      editable
      label
      helpContent
    }
    subtypes {
      label
      value
    }
  }
}
`;

const SEARCH_DOCUMENTS = gql`
query GetVeevaInsciteDocuments($searchQuery: String!) {
  getVeevaInsciteDocuments(searchQuery: $searchQuery) {
    insciteDocuments {
      id
      type
      subtype
      status
      documentNumber
      props {
        label
        value
      }
    }
  }
}
`;

type TypesResultType = {
  getVeevaInsciteDocumentTypes: {
    insciteFieldData: VeevaInsciteDocumentTypeItem[];
  };
};

type TypeResultType = {
  getVeevaInsciteDocumentType: {
    value: string;
    label: string;
    fields: DocumentPropertyResponse[];
    subtypes: DocumentTypeReference[];
  };
};

type DocumentsResultType = {
  getVeevaInsciteDocuments: {
    insciteDocuments: VeevaInsciteDocumentItem[];
  };
};

const cleanDocumentsApolloData = (data?: DocumentsResultType): null | { id: VeevaInsciteDocumentItem; label: string }[] => {
  if (data) {
    return data?.getVeevaInsciteDocuments.insciteDocuments.map((document) => {
      const documentProps: Record<string, string | boolean> = document.props.reduce((acc, { label, value }) => {
        if (label === 'target_release_date__c') {
          const [date] = new Date(value as string).toISOString().split('T');
          acc[label] = date;
        } else {
          acc[label] = value;
        }
        return acc;
      }, {} as Record<string, string | boolean>);
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { status, documentNumber } = document;
      const label = `[${documentNumber}] - (${status}) ${documentProps.title__v} `;
      return { id: document, label };
    });
  }
  return null;
};

export const useGetVeevaInsciteDocumentTypes = (parentType?: string) => {
  const { data, loading, error } = useQuery<TypesResultType>(GET_DOCUMENT_TYPES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      parentType: parentType || '',
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getVeevaInsciteDocumentTypes.ts - getVeevaInsciteDocumentTypes',
    });
  }
  return {
    data: data?.getVeevaInsciteDocumentTypes.insciteFieldData || [],
    loading,
    error,
  };
};

export const useGetVeevaInsciteDocumentType = () => {
  const [queryFetchDocumentType, { data, loading, error, called }] = useLazyQuery<TypeResultType>(GET_DOCUMENT_TYPE, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getVeevaInsciteDocumentType.ts - getVeevaInsciteDocumentType',
    });
  }

  return {
    queryFetchDocumentType,
    data,
    loading,
    error,
    called,
  };
};

export const useGetVeevaInsciteDocuments = () => {
  const [queryGetDocuments, { data, error, loading }] = useLazyQuery<DocumentsResultType>(SEARCH_DOCUMENTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const lazyQuery = useCallback(async (searchQuery?: string) => {
    await queryGetDocuments(
      {
        variables: {
          searchQuery: searchQuery || '',
        },
      },
    );
  }, [queryGetDocuments]);

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getVeevaInsciteDocuments.ts - getVeevaInsciteDocuments',
    });
  }
  return {
    lazyQuery,
    data: cleanDocumentsApolloData(data) || [],
    loading,
    error,
  };
};
