import { gql, useLazyQuery } from '@apollo/client';
import { VeevaInscitePicklistItem } from 'modules/events/components/EventTesting/VeevaInsciteSyncModal/VeevaInsciteSyncModal.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_PICKLIST_VALUES = gql`
query GetVeevaInscitePicklistValuesQuery($objectType: String!) {
  getVeevaInscitePicklistValues(objectType: $objectType) {
    picklistValues {
      name
      label
    }
  }
}
`;

type ResultType = {
  getVeevaInscitePicklistValues: {
    picklistValues: VeevaInscitePicklistItem[];
  };
};

const useGetVeevaInscitePicklistValues = () => {
  const [queryFetchPicklistValues, { data, loading, error, called }] = useLazyQuery<ResultType>(GET_PICKLIST_VALUES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getVeevaInscitePicklistValues.ts - getVeevaInscitePicklistValues',
    });
  }
  return {
    queryFetchPicklistValues,
    data,
    loading,
    error,
    called,
  };
};

export default useGetVeevaInscitePicklistValues;
