import { TimeZone } from 'basics/enums/timeZoneList.enums';
import { getUTCDate } from 'basics/utils/dates/dates.utils';
import { OriginMarket } from 'generated/event.types';
import { CreateEventFormValues } from 'modules/events/components/CreateEvent/CreateEventModal/CreateEventModal.type';

const transformToEvent = (values: CreateEventFormValues) => {
  const startDate = values.dateRange[0];
  const endDate = values.dateRange[1];

  return {
    title: values.title,
    format: values.format,
    product: values.product,
    hostProvider: values.hostProvider,
    presence: values.presence,
    market: values.market,
    originMarket: Object.values(OriginMarket).includes(values.market.toUpperCase() as unknown as OriginMarket)
      ? (values.market.toUpperCase() as unknown as OriginMarket)
      : OriginMarket.US,
    session: {
      startDate: getUTCDate(startDate || undefined, values.startTime, values.timezone) || new Date(),
      endDate: getUTCDate(endDate || undefined, values.endTime, values.timezone) || new Date(),
      timeZone: TimeZone.UTC,
    },
    venue: values.venue,
  };
};

export default transformToEvent;
